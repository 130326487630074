import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Editor1 from './Editor1'
import Navbar from './Navbar'
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';




const Model = () => {
    const [html, setHtml] = useState('')
    const [css, setCss] = useState('')
    const [js, setJs] = useState('')
    const [srcDoc, setSrcDoc] = useState('')
    const [theme , setTheme ] = useState('dark')
    const [codeTheme, setcodeTheme] = useState("vs-dark")


    useEffect(() => {
        const timeout = setTimeout(() => {
          setSrcDoc(`
            <html>
              <body>${html}</body>
              <style>${css}</style>
              <script>${js}</script>
            </html>
          `)
        }, 250)
    
        return () => clearTimeout(timeout)
      }, [html, css, js])


      const handleTheme = (e) => {
        switch(theme) {
          case 'dark' : 
          setTheme('light')
          setcodeTheme("vs-light")
          break;
          case 'light' :
          setTheme('dark')
          setcodeTheme("vs-dark")
          break;
          default :
          setTheme('dark')
          setcodeTheme("vs-dark")
        }

      }

// #D1D0CD

  return (
    <>
    <CompilerBody style={{backgroundColor : theme === 'light' ? '#fff' : '#060606'}}>
    <Navbar handleTheme={handleTheme} theme={theme}/>

    <InputSection>
    <Container>
    <Editor1
    imgSrc="/assets/html5.png"
    language="xml"
    displayName="HTML"
    codeTheme={codeTheme}
    value={html}
    onChange={setHtml}
    />

    <Editor1
    imgSrc="/assets/css3.png"
    language="css"
    displayName="CSS"
    codeTheme={codeTheme}
    value={css}
    onChange={setCss}
    />


    <Editor1
    imgSrc="/assets/js.png"
    language="javascript"
    displayName="Javascript"
    codeTheme={codeTheme}
    value={js}
    onChange={setJs}
    />
    </Container>

    </InputSection>




    <OutputSection style={{backgroundColor : codeTheme === "vs-light" ? '#E4E4E4' : '#21252B'}}>
    <Btns>
    <button style={{backgroundColor : codeTheme === "vs-light" ? '#FAF8F5' : '#383E4A', color : codeTheme === "vs-light" ? "#000" : "#fff"}}>Output</button>
    <button style={{backgroundColor : codeTheme === "vs-light" ? '#D1D0CD' : '#21252B', color : codeTheme === "vs-light" ? "#000" : "#fff"}}>Console</button>
    </Btns>

    <Settings>

    <button>
    <SettingsIcon/>
    </button>

    <button>
    <ExpandMoreIcon/>
    </button>
    </Settings>
    </OutputSection>

    <Main>
    <iframe 
    title='output' 
    srcDoc={srcDoc}
    sandbox='allow-scripts'
    width="100%"
    height="100%"
    ></iframe>

    </Main>


    </CompilerBody>



    {/* <Container>
    <Editor1
    language="HTML"
    displayName="HTML"
    value={html}
    onChange={setHtml}
    />
    <Editor1
    language="css"
    displayName="CSS"
    value={css}
    onChange={setCss}
    />
    <Editor1
    language="javascript"
    displayName="javascript"
    value={js}
    onChange={setJs}
    />
    </Container>


    <Main>
    <iframe 
    title='output' 
    srcDoc={srcDoc}
    sandbox='allow-scripts'
    width="100%"
    height="100%"
    ></iframe>

    </Main> */}
      
    </>
  )
}

const CompilerBody = styled.div`
background-color: #060606;
width: 100%;
height: 100vh;
overflow: hidden;
`;

const InputSection = styled.div`
/* background-color: #060606; */
/* background: red; */
/* color: #fff; */
/* padding: 15px 20px; */
position: relative;
/* display: flex;
align-items: center;
justify-content: space-between; */
padding: 0 15px;

/* margin: 0 10px; */


`

const Container = styled.div`
/* background-color: red; */
position: relative;
height: 50%;
/* width: 100%; */
display: flex;
align-items: center;
gap: 15px;
/* overflow: hidden; */

/* display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 20px; */

`;

const OutputSection = styled.div`
overflow: hidden;
box-shadow: none;
display: flex;
align-items: center;
justify-content: space-between;
padding: 0;
width: 100%;
background-color: red;
color: #fff;
background: #21252B;
`;

const Btns = styled.div`
display: flex;



button {
  outline: none;
  border: none;
  background: #21252B;
  color: #fff;
  padding: 10px 10px;
  cursor: pointer;
  font-size: 0.8rem;


  &:nth-child(1) {
    background: #383E4A;
  }
}
` ;



const Settings = styled.div`
display: flex;
  align-items: center;
  justify-content: center;


button {
  outline: none;
  border: none;
  background-color: transparent;
  /* background: #21252B;
  color: #fff; */
  cursor: pointer;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  
  
  .MuiSvgIcon-root {
  font-size: 1rem;
  color: #5F7E97;
}

  
}

`

const Main = styled.div`
background-color: #fff;
height: 100%;


iframe { 
  border: none;
}
`



export default Model
