import './App.css';
// import Home from './components/Home';
import Model from './components/Model';

function App() {
  return (
    <>
    {/* <Home/> */}
    <Model/>
    </>
  );
}

export default App;
