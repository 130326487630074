import React, {useState} from 'react'
import styled from 'styled-components'
import Editor from "@monaco-editor/react";
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Editor1 = (props) => {
    const {language, displayName, value, onChange, imgSrc, codeTheme} = props
    const [grow, setGrow] = useState(false)


    const handleEditorChange = (value, event) => {
        console.log(value);
        onChange(value)
    }


    const handleGrow = () => {
        setGrow((prev) => {
            return !prev
        })
    }


    // #E4E4E4

  return (
    <>

    <Main style={{width : grow ? '15%' : '100%'}}>
    <Nav style={{backgroundColor : codeTheme === "vs-light" ? '#E4E4E4' : '#21252B'}}>
        <Header style={{backgroundColor : codeTheme === "vs-light" ? '#fff' : '#383E4A' , color : codeTheme === "vs-light" ? "#000" : "#fff"}}>
        <img src={imgSrc} alt="HTML" />
       <span> {displayName}</span>
        </Header>

      <Settings>
      <button>
      <SettingsIcon/>
      </button>

      <button onClick={handleGrow}>
      <ExpandMoreIcon/>
      </button>
      </Settings>
    </Nav>
    <Editor
        height="90vh"
        theme={codeTheme}
        language={language}
        defaultLanguage={language}
        value={value}
        onChange={handleEditorChange}
    />
    


    </Main>
    </>
  )
}

const Main = styled.div`
height: 350px;
/* padding: 0 0.5rem; */
max-width: 619px;
/* flex-grow: 1;
flex-basis: 0; */
/* width: 100%; */
/* flex-basis: 33%; */
/* flex-basis: 100%; */
overflow: hidden;
flex-grow: 1;
transition: all 0.5s ease-in-out;
box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.3);


`

const Nav = styled.div`
background-color: #21252B;
display: flex;
align-items: center;
justify-content: space-between;
`;

const Header = styled.div`
color: #fff;
background-color: #383E4A;
width: fit-content;
padding: 5px 15px;
font-weight: 500;
display: flex;
align-items: center;
justify-content: center;
gap: 5px;

span {
    font-size: 0.9rem;
    font-weight: 500;
}
img {
    width: 12px;
    height: 12px;
}
`;

const Settings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {

  outline: none;
  border: none;
  background-color: transparent;
  /* background: #21252B; */
  /* color: #fff; */
  cursor: pointer;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */



  .MuiSvgIcon-root {
  font-size: 1rem;
  color: #5F7E97;
 }

  
 }

`

export default Editor1
