import React, {useState} from 'react'
import styled from 'styled-components'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import WindowIcon from '@mui/icons-material/Window';
import WbSunnyIcon from '@mui/icons-material/WbSunny';

const Navbar = (props) => {
    const {handleTheme, theme} = props
    const [light, setLight] = useState('Light')

    const setColor = () => {
        handleTheme()
        if(light === 'Light') {
            setLight('Dark')
        }
        else {
            setLight('Light')
        }
    }



  return (
    <Container style={{backgroundColor : theme === 'light' ? '#fff' : '#060606' , color : theme === 'light' ? "#000" : "#fff"}}>
    <Nav>
    <Header>
    <Logo>
        <img src="/assets/rogue-logo.jpeg" alt="" />
    </Logo>
    <Heading>
    <h1>Code Editor</h1>
    <p>Captain Anonymous</p>
    </Heading>

    </Header>


    <NavList>

        {/* <NavItem>
        <Btns>
        <SaveIcon/>
            Save
        </Btns>
        </NavItem> */}

        {/* <NavItem>
            <SettingBtn>
            <SettingsIcon/>
                Settings
            </SettingBtn>
        </NavItem> */}

        <NavItem>
        <WindowIcon/>
        </NavItem>

        <NavItem>
        <Light onClick={setColor} style={{color : theme === 'light' ? "#000" : "#fff"}}>
        {
            theme === 'light' ? <WbSunnyIcon className='sun'/> : <WbSunnyOutlinedIcon/>
        }
            <span>{light}</span>
        </Light>
        </NavItem>

        {/* <NavItem>
        <a>
        <User>
            <img src="https://imageio.forbes.com/specials-images/imageserve/61804affb271023c1ae8920b/Laughing-young-man-wearing-headphones-using-laptop-at-desk-in-office/960x0.jpg?format=jpg&width=960" alt="" />
        </User>

        </a>
        </NavItem> */}

    </NavList>


        
    </Nav>
    
    </Container>
  )
}

const Container = styled.div`
background-color: #060606;
color: #fff ;
padding: 15px 20px;
position: relative;

`

const Nav = styled.nav`
display: flex;
align-items: center;
justify-content: space-between;
/* min-height: 50px; */
`;

const Header = styled.div`
display: flex;
align-items: center;
gap: 10px;
justify-content: center;
`

const Heading = styled.div`
h1 {
    font-size: 1.2rem;
    font-weight: 700;
    
}
p {
    font-size: 0.8rem;
    font-weight: 400;
}
`;




const Logo = styled.div`
width: 50px;
height: 50px;
border-radius: 5px;
/* box-shadow: 0 5px 5px rgba(0,0,0,0.5); */
box-shadow: 0px 2px 2px 0px #00000040;
overflow: hidden;
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`



const NavList = styled.ul`
display: flex;
align-items: center;
justify-content: center;
list-style: none;
gap: 20px;


`;


const NavItem = styled.li`
`;


const Btns = styled.button`
    outline: none;
    border: none;
    background-color: transparent;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    background-color: #86D315;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8rem;

    .MuiSvgIcon-root {
        font-size: 1.2rem;
    }
    /* #3D3F45 */
    /* transition: all 0.3s ease-in-out; */
    /* &:hover {
        background-color: #fff;
        color: #060606;
    } */

`;


const SettingBtn = styled(Btns)`
background-color: #3D3F45;
`;


const Light = styled(Btns)`
display: flex;
align-items: center;
gap: 5px;
flex-direction: column;
background-color: transparent;
padding: 0;
font-size: 0.8rem;
span {
    font-size: 0.7rem;
    font-weight: 500;
}
`;


const User = styled.div`
width: 40px;
height: 40px;
border-radius: 50%;
overflow: hidden;
cursor: pointer;
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`


export default Navbar
